<template>
  <div class="sessions-container result-sent">
    <div class="sessions-box-container">
      <div class="session-box">
        <div class="box">
          <div class="box-content">
            <div class="image-container">
              <initials-circle
                class="gray-border-circle"
                :first="firstInitial"
                :second="secondInitial"
              />
            </div>
            <div class="text-container">
              <h2>{{ $t('thanksUser', { name: user.firstName }) }}</h2>
              <p class="message">
                {{ $t('sessionFinishedText') }}
              </p>
            </div>
          </div>
          <div class="actions-container">
            <router-link
              :to="{ name: 'Session' }"
              class="btn btn-primary"
            >
              {{ $t("close") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InitialsCircle from '@/components/common/InitialsCircle'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'SessionResultSent',
  components: {
    InitialsCircle
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters({
      isSessionFinished: 'finishSession',
      user: 'loggedInUser'
    })
  },
  created () {
    this.$store.dispatch('JUST_FINISHED_SESSION', true)
    if (!this.isSessionFinished) {
      return this.$router.push({ name: 'Session' })
    }
    this.$store.dispatch('FINISH_SESSION', false)
  }
}
</script>
